// ReSharper disable UndeclaredGlobalVariableUsing
// ReSharper disable PossiblyUnassignedProperty

import PerfectScrollbar from "perfect-scrollbar";
import "nivo-lightbox"

(function ($) {
    "use strict";

    $(window).load(function () {
        $("#page-loader").delay(300).fadeOut(400);

        $("#loader-name").addClass("loader-left");
        $("#loader-job").addClass("loader-right");
        $("#loader-animation").addClass("loader-hide");
    });

    $(document).ready(function () {

        $("#loading-wraper").fadeIn(300);

        $(".map").on("click", function () {
            $(".map-overlay").hide();
        });

        $(".map").on("mouseleave", function () {
            $(".map-overlay").show();
        });

        $("a.nivobox").nivoLightbox({ effect: "fade" });

        const ps = new PerfectScrollbar(".section-vcardbody", {
            wheelSpeed: 0.9
        });

        var linkHome = 0;
        var linkPage = "";

        function pageOn() {
            $("#main-menu").addClass("main-menu-pgactive");
            $("#section-home").addClass("section-vcardbody-pgactive");

            linkHome = 1;
        }

        function pageOff() {
            $(".section-page-active").removeClass("section-page-active");
            $("#main-menu").removeClass("main-menu-pgactive");
            $("#section-home").removeClass("section-vcardbody-pgactive");
            linkHome = 0;
        }

        $(".link-page").on("click", function (event) {
            event.preventDefault();
            $(".menuActive").removeClass("menuActive");
            $(this).addClass("menuActive");
            linkPage = $(this).attr("href");
            $(".section-page-active").removeClass("section-page-active");
            $(linkPage).addClass("section-page-active");
            pageOn();
        });

        $(".link-home").on("click", function (event) {
            event.preventDefault();

            if (linkHome === 1) {
                $(".menuActive").removeClass("menuActive");
                $(this).addClass("menuActive");
                pageOff();
            }
        });

        $(".modal-wrap").click(function () {
            $(".modal-wrap").fadeOut(300);
        });
    });
})(jQuery);